<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="onSubmit()">
      <v-row class="form-container rounded-lg">
        <v-col cols="12">
          <h3>
            ฟอร์มกรอกข้อมูลผู้ใช้
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-text-field
            v-model="formData.username"
            label="ชื่อผู้ใช้"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-text-field
            v-model="formData.password"
            :type="passwordType"
            label="รหัสผ่าน"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            required
            :append-icon="passwordType === 'password' ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
            @click:append="togglePassword()" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-text-field
            v-model="formData.email"
            label="อีเมล"
            :rules="emailBoxRules"
            color="primary"
            outlined
            dense
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-autocomplete
            v-model="formData.role"
            :items="roles"
            item-text="text"
            item-value="value"
            :rules="textBoxRules"
            outlined
            dense
            label="Role"
            placeholder="โปรดเลือก"
            required />
        </v-col>
        <v-col
          v-if="formData.role === 'vendor'"
          cols="12"
          md="6">
          <v-autocomplete
            v-model="formData.vendor"
            :items="vendors"
            item-text="name"
            item-value="id"
            :rules="textBoxRules"
            outlined
            dense
            return-object
            label="เวนเดอร์"
            placeholder="โปรดเลือก"
            required />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import UsersProvider from '@/resources/users.provider'
import VendorsProvider from '@/resources/vendors.provider'

const UsersService = new UsersProvider()
const VendorsService = new VendorsProvider()

export default {
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    emailBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน',
      (v) => /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    formData: {
      username: '',
      password: '',
      email: '',
      vendor: null,
      role: ''
    },
    roles: [
      {
        text: 'Administrator',
        value: 'administrator'
      },
      {
        text: 'Vendor',
        value: 'vendor'
      }
    ],
    vendors: [],
    passwordType: 'password'
  }),
  computed: {
    isEdit () {
      return !!this.$route.params?.id
    }
  },
  async mounted () {
    await this.getVendors()

    if (this.isEdit) {
      this.getItemById()
    }
  },
  methods: {
    togglePassword () {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    onBack () {
      this.$router.push({ name: 'SettingsUser' })
    },
    async getVendors () {
      try {
        this.loading = true

        const { data } = await VendorsService.getItems({
          page: 1,
          limit: 99999
        })

        this.vendors = data.results
      } catch (error) {
        console.error('getVendors', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await UsersService.getUserById(this.$route.params.id)

        this.formData = { ...data }
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        if (!this.isEdit) {
          await UsersService.create(this.formData)
        } else {
          await UsersService.updateUserById(this.formData)
        }

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: `${this.isEdit ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลเสร็จสิ้น`,
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'SettingsUser' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
